import React from 'react';
import hexToRGBA from '../hexToRGBA';
import { Link } from 'react-router-dom';

const updateAddNewLabels = (event) => {
  // Dynamically change text field labels based on type
  let labels = [
    {
      issues: 'Type',
      employees: 'Name',
      positions: 'Title',
      candidate: 'Name',
    },
    {
      issues: 'Employee',
      employees: 'Email',
      positions: 'Hiring Manager',
      candidate: 'Position',
    },
    {
      issues: 'Severity',
      employees: 'Title',
      positions: 'Status',
      candidate: 'Status',
    },
  ];

  let selectedValue =
    event?.target?.value ||
    document.getElementById('create-select-type').nextElementSibling.value;

  labels.forEach((d, i) => {
    // Label text
    document.getElementById(`create-text-field-${i}-label`).innerText =
      labels[i][selectedValue];
    // Child span that sets width of text when focused
    document.getElementById(
      `create-text-field-${i}`
    ).nextElementSibling.children[0].children[0].innerText =
      labels[i][selectedValue];
    // Input name attribute
    document.getElementById(`create-text-field-${i}`).name =
      labels[i][selectedValue];
  });
};

export default {
  styles: {
    display: 'flex',
  },
  opts: {},
  id: '',
  classes: '',
  favicon: '/hr/logos/main.ico',
  pendoConfig: {
    name: 'hr',
    apiKey: '744839ca-7b1c-4c44-6da5-97e001bed151',
    additionalApiKeys: [],
    // snippetCallback: function (config, urlParams) {},
    visitor: {},
    location: {
      transforms: [
        {
          attr: 'search',
          action: 'ExcludeKeys',
          data: [
            'obj',
            'disablePendo',
            'apiKey',
            'visitor',
            'account',
            'accountBasedVisitor',
            'role',
            'team',
            'title',
            'region',
            'office',
            'system',
          ],
        },
      ],
    },
  },
  background: {
    styles: {
      backgroundImage: 'url("/common/images/header-bg.png")',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '105% 300px',
      filter:
        'brightness(0) saturate(100%) invert(12%) sepia(56%) saturate(3648%) hue-rotate(255deg) brightness(91%) contrast(93%)',
    },
    opts: {},
    id: '',
    classes: 'background-image',
  },
  header: {
    styles: {},
    opts: {},
    id: 'header',
    classes: '',
    navBar: {
      styles: {},
      opts: {},
      id: '',
      classes: '',
      contents: [
        {
          styles: {},
          opts: { 'aria-label': 'navigation-title' },
          id: 'hr-site-title',
          classes: '',
          componentName: 'NavTitle',
          alignment: 'left',
          name: 'HR',
        },
        {
          styles: {},
          opts: {},
          id: 'help-button',
          classes: '',
          componentName: 'Button',
          alignment: 'right',
          type: 'Link',
          icon: 'ContactSupport',
          tooltipText: 'Go to Help Center',
          href: 'https://support.pendo.io/hc/en-us',
          target: '_blank',
        },
        {
          styles: {},
          opts: {},
          id: '',
          classes: 'profile-button',
          componentName: 'NavAvatar',
          alignment: 'right',
        },
      ],
      navItems: [
        {
          name: 'Overview',
          type: 'route',
          path: '/',
        },
        {
          name: 'Employees',
          type: 'route',
          path: '/employees',
        },
        {
          name: 'Issues',
          type: 'route',
          path: '/issues',
        },
        {
          name: 'Positions',
          type: 'route',
          path: '/positions',
        },
        {
          name: 'Candidates',
          type: 'route',
          path: '/candidates',
        },
      ],
    },
    appBars: [
      {
        styles: {},
        opts: {},
        id: '',
        classes: '',
        contents: [
          {
            styles: {},
            opts: {},
            id: 'search-bar',
            componentName: 'NavSearch',
            alignment: 'left',
          },
          {
            styles: {
              backgroundColor: '#1DA259',
              '&:hover': {
                backgroundColor: '#1a9150',
              },
            },
            opts: {
              variant: 'contained',
            },
            id: 'create',
            classes: '',
            componentName: 'Button',
            alignment: 'right',
            type: 'Modal',
            name: 'Add New',
            openStartCallback: () => {
              // Set dynamic labels for add new form before it is rendered
              updateAddNewLabels();

              // Add '/add' to url using pendo location api when add new form open
              let baseUrl = pendo.location.getHref();
              baseUrl = baseUrl.split('?');
              pendo.location.setUrl(
                baseUrl[0].slice(-1) === '/'
                  ? `${baseUrl[0]}new`
                  : `${baseUrl[0]}/new`
              );
            },
            closeEndCallback: () => {
              // Return to using browser url on form closed
              pendo.location.useBrowserUrl();
            },
            header: {
              styles: {},
              opts: {},
              id: '',
              classes: '',
              name: 'Create',
            },
            content: {
              styles: {},
              opts: {},
              id: '',
              classes: '',
              componentName: 'Form',
              contents: [
                {
                  styles: {},
                  opts: {},
                  id: 'create-select-type',
                  classes: '',
                  componentName: 'Select',
                  label: 'Type',
                  options: [
                    {
                      name: 'Issue',
                      value: 'issues',
                    },
                    {
                      name: 'Employee',
                      value: 'employees',
                    },
                    {
                      name: 'Candidate',
                      value: 'candidate',
                    },
                    {
                      name: 'Position',
                      value: 'positions',
                    },
                  ],
                  default: () => {
                    // Dynamically assign default based on path
                    return window.location.pathname.split('/')[1] || 'issues';
                  },
                  changeEndCallback: updateAddNewLabels, // Update dynamic add new form labels on select change
                },
                {
                  styles: {},
                  opts: {},
                  id: 'create-text-field-0',
                  classes: '',
                  componentName: 'TextField',
                  label: 'Default',
                },
                {
                  styles: {},
                  opts: {},
                  id: 'create-text-field-1',
                  classes: '',
                  componentName: 'TextField',
                  label: 'Default',
                },
                {
                  styles: {},
                  opts: {},
                  id: 'create-text-field-2',
                  classes: '',
                  componentName: 'TextField',
                  label: 'Default',
                },
              ],
              submitCallback: function (event, navigate) {
                // Get details from DOM and use to navigate to new details page
                let url = `/${
                  document.getElementById('create-select-type')
                    .nextElementSibling.value
                }/new/details?obj={`;

                // Store form fields as query params
                for (let i = 0; i < 3; i++) {
                  let el = document.getElementById(`create-text-field-${i}`);
                  url += `${i ? ',' : ''}"${encodeURIComponent(
                    el.name
                  )}":"${encodeURIComponent(el.value)}"`;
                }

                navigate(`${url}}`);
              },
            },
          },
        ],
      },
    ],
  },
  content: {
    styles: {},
    opts: {},
    id: '',
    classes: '',
    routes: [
      {
        name: 'Overview',
        route: '/',
        contents: [
          {
            styles: {},
            opts: {
              container: true,
            },
            id: '',
            classes: '',
            componentName: 'Grid',
            contents: [
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                  lg: 8,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      container: true,
                    },
                    id: '',
                    classes: '',
                    componentName: 'Grid',
                    contents: [
                      {
                        styles: {},
                        opts: {
                          item: true,
                          xs: 12,
                        },
                        id: '',
                        classes: '',
                        componentName: 'Grid',
                        contents: [
                          {
                            styles: {},
                            opts: {
                              height: 0.5,
                              header: {
                                styles: {},
                                opts: {},
                                id: '',
                                classes: '',
                                name: 'Issue Count by Week',
                              },
                              content: {
                                styles: {},
                                opts: {
                                  altText:
                                    'Line chart of issue count week over week',
                                  type: 'Line',
                                  data: {
                                    labels: [
                                      'Week 1',
                                      'Week 2',
                                      'Week 3',
                                      'Week 4',
                                      'Week 5',
                                      'Week 6',
                                      'Week 7',
                                      'Week 8',
                                      'Week 9',
                                      'Week 10',
                                      'Week 11',
                                      'Week 12',
                                    ],
                                    datasets: [
                                      {
                                        fill: true,
                                        label: 'Issue Count',
                                        data: [
                                          1, 3, 6, 2, 1, 0, 0, 7, 8, 4, 2, 0,
                                        ],
                                        backgroundColor: hexToRGBA(
                                          '#0d88e6',
                                          0.7
                                        ),
                                        borderColor: hexToRGBA('#0d88e6', 0.9),
                                        pointBackgroundColor: hexToRGBA(
                                          '#0d88e6',
                                          0.8
                                        ),
                                        pointBorderColor: hexToRGBA(
                                          '#0d88e6',
                                          1
                                        ),
                                      },
                                    ],
                                  },
                                  opts: {
                                    maintainAspectRatio: false,
                                    responsive: true,
                                    plugins: {
                                      legend: {
                                        display: false,
                                      },
                                    },
                                  },
                                },
                                id: '',
                                classes: '',
                                type: 'Chart',
                              },
                            },
                            id: 'forecast-card',
                            classes: '',
                            componentName: 'ContentTile',
                          },
                        ],
                      },
                      {
                        styles: {},
                        opts: { item: true, xs: 12, md: 6 },
                        id: '',
                        classes: '',
                        componentName: 'Grid',
                        contents: [
                          {
                            styles: {},
                            opts: {
                              height: 0.5,
                              header: {
                                styles: {},
                                opts: {},
                                id: '',
                                classes: '',
                                name: 'Open Issues by Severity',
                              },
                              content: {
                                styles: {},
                                opts: {
                                  altText:
                                    'Bar chart of open issues by severity label',
                                  type: 'Bar',
                                  data: {
                                    labels: [
                                      'New',
                                      'Open',
                                      'Pending Close',
                                      'Closed',
                                    ],
                                    datasets: [
                                      {
                                        label: 'Issue Count',
                                        data: [5, 13, 2, 25],
                                        backgroundColor: hexToRGBA(
                                          '#1DA259',
                                          0.7
                                        ),
                                        borderColor: hexToRGBA('#1DA259', 0.9),
                                        hoverBackgroundColor: hexToRGBA(
                                          '#1DA259',
                                          0.9
                                        ),
                                        hoverBorderColor: hexToRGBA(
                                          '#1DA259',
                                          1
                                        ),
                                      },
                                    ],
                                  },
                                  opts: {
                                    maintainAspectRatio: false,
                                    plugins: {
                                      tooltip: {
                                        callbacks: {
                                          label: function (context) {
                                            return (
                                              '$' + context.raw / 1000 + 'k'
                                            );
                                          },
                                        },
                                      },
                                      legend: {
                                        display: false,
                                      },
                                    },
                                    scales: {},
                                    indexAxis: 'y',
                                  },
                                },
                                id: '',
                                classes: '',
                                type: 'Chart',
                              },
                            },
                            id: 'quota-card',
                            classes: '',
                            componentName: 'ContentTile',
                          },
                        ],
                      },
                      {
                        styles: {},
                        opts: { item: true, xs: 12, md: 6 },
                        id: '',
                        classes: '',
                        componentName: 'Grid',
                        contents: [
                          {
                            altText:
                              'Doughnut chart of open positions by stage',
                            styles: {},
                            opts: {
                              height: 0.5,
                              header: {
                                styles: {},
                                opts: {},
                                id: '',
                                classes: '',
                                name: 'Open Positions by Stage',
                              },
                              content: {
                                styles: {},
                                opts: {
                                  type: 'Doughnut',
                                  data: {
                                    labels: [
                                      'New',
                                      'Applications Closed',
                                      'Final Round',
                                      'Offer Out',
                                    ],
                                    datasets: [
                                      {
                                        data: [2, 10, 16, 7],
                                        backgroundColor: [
                                          hexToRGBA('#f1416c', 0.7),
                                          hexToRGBA('#7239ea', 0.7),
                                          hexToRGBA('#0d88e6', 0.7),
                                          hexToRGBA('#ffc700', 0.7),
                                        ],
                                        borderColor: [
                                          hexToRGBA('#f1416c', 1),
                                          hexToRGBA('#7239ea', 1),
                                          hexToRGBA('#0d88e6', 1),
                                          hexToRGBA('#ffc700', 1),
                                        ],
                                        hoverBackgroundColor: [
                                          hexToRGBA('#f1416c', 0.9),
                                          hexToRGBA('#7239ea', 0.9),
                                          hexToRGBA('#0d88e6', 0.9),
                                          hexToRGBA('#ffc700', 0.9),
                                        ],
                                        borderWidth: 1,
                                      },
                                    ],
                                  },
                                  opts: {
                                    maintainAspectRatio: false,
                                    plugins: { legend: { position: 'right' } },
                                  },
                                },
                                id: '',
                                classes: '',
                                type: 'Chart',
                              },
                            },
                            id: 'pipeline-card',
                            classes: '',
                            componentName: 'ContentTile',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                  lg: 4,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 1,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'All Open Positions',
                      },
                      content: {
                        styles: {},
                        opts: {
                          dataUrl: '/hr/tableData/positions.json',
                          columns: [
                            {
                              field: 'title',
                              headerName: 'Title',
                              flex: 1,
                              renderCell: (params) => (
                                <a href={`/positions/${params.id}/details`}>
                                  {params.formattedValue}
                                </a>
                              ),
                            },
                            {
                              field: 'status',
                              headerName: 'Status',
                              flex: 1,
                            },
                          ],
                        },
                        id: '',
                        classes: '',
                        type: 'Table',
                      },
                    },
                    id: '',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Employees',
        route: '/employees',
        contents: [
          {
            styles: {},
            opts: {
              container: true,
            },
            id: '',
            classes: '',
            componentName: 'Grid',
            contents: [
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 1,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'Employees',
                      },
                      content: {
                        styles: {},
                        opts: {
                          dataUrl: '/hr/tableData/employees.json',
                          columns: [
                            {
                              field: 'name',
                              headerName: 'Name',
                              renderCell: (params) => (
                                <Link to={`/employees/${params.id}/details`}>
                                  {params.formattedValue}
                                </Link>
                              ),
                              flex: 1,
                            },
                            {
                              field: 'title',
                              headerName: 'Title',
                              flex: 1,
                            },
                            {
                              field: 'team',
                              headerName: 'Team',
                              flex: 1,
                            },
                            {
                              field: 'email',
                              headerName: 'Email',
                              flex: 1,
                            },
                            {
                              field: 'phone',
                              headerName: 'Phone',
                              flex: 3,
                            },
                          ],
                        },
                        id: '',
                        classes: '',
                        type: 'Table',
                      },
                    },
                    id: '',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Issues',
        route: '/issues',
        contents: [
          {
            styles: {},
            opts: {
              container: true,
            },
            id: '',
            classes: '',
            componentName: 'Grid',
            contents: [
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 1,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'Issues',
                      },
                      content: {
                        styles: {},
                        opts: {
                          dataUrl: '/hr/tableData/issues.json',
                          columns: [
                            {
                              field: 'id',
                              headerName: 'ID',
                              flex: 1,
                              renderCell: (params) => (
                                <Link to={`/issues/${params.id}/details`}>
                                  {params.formattedValue}
                                </Link>
                              ),
                            },
                            {
                              field: 'severity',
                              headerName: 'Severity',
                              flex: 1,
                            },
                            {
                              field: 'status',
                              headerName: 'Status',
                              flex: 1,
                            },
                            {
                              field: 'type',
                              headerName: 'Type',
                              flex: 1,
                            },
                            {
                              field: 'days',
                              headerName: 'Open Days',
                              flex: 1,
                            },
                          ],
                        },
                        id: '',
                        classes: '',
                        type: 'Table',
                      },
                    },
                    id: '',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Positions',
        route: '/positions',
        contents: [
          {
            styles: {},
            opts: {
              container: true,
            },
            id: '',
            classes: '',
            componentName: 'Grid',
            contents: [
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 1,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'Positions',
                      },
                      content: {
                        styles: {},
                        opts: {
                          dataUrl: '/hr/tableData/positions.json',
                          columns: [
                            {
                              field: 'title',
                              headerName: 'Title',
                              flex: 1,
                              renderCell: (params) => (
                                <Link to={`/positions/${params.id}/details`}>
                                  {params.formattedValue}
                                </Link>
                              ),
                            },
                            {
                              field: 'team',
                              headerName: 'Team',
                              flex: 1,
                            },
                            {
                              field: 'manager',
                              headerName: 'Hiring Manager',
                              flex: 1,
                            },
                            {
                              field: 'status',
                              headerName: 'Status',
                              flex: 1,
                            },
                          ],
                        },
                        id: '',
                        classes: '',
                        type: 'Table',
                      },
                    },
                    id: '',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Candidates',
        route: '/candidates',
        contents: [
          {
            styles: {},
            opts: {
              container: true,
            },
            id: '',
            classes: '',
            componentName: 'Grid',
            contents: [
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 1,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'Candidates',
                      },
                      content: {
                        styles: {},
                        opts: {
                          dataUrl: '/hr/tableData/candidates.json',
                          columns: [
                            {
                              field: 'name',
                              headerName: 'Name',
                              flex: 1,
                              renderCell: (params) => (
                                <Link to={`/candidates/${params.id}/details`}>
                                  {params.formattedValue}
                                </Link>
                              ),
                            },
                            {
                              field: 'position',
                              headerName: 'Position',
                              flex: 1,
                            },
                            {
                              field: 'Email',
                              headerName: 'Email',
                              flex: 1,
                            },
                            {
                              field: 'referral',
                              headerName: 'Referral',
                              flex: 1,
                            },
                          ],
                        },
                        id: '',
                        classes: '',
                        type: 'Table',
                      },
                    },
                    id: '',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        name: 'Details',
        route: '/:detailType/:detailId/details',
        contents: [
          {
            styles: {},
            opts: {
              container: true,
            },
            id: '',
            classes: '',
            componentName: 'Grid',
            contents: [
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                  sm: 5,
                  md: 4,
                  lg: 3,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 0.5,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'Quick Information',
                      },
                      content: {
                        styles: {},
                        opts: {
                          schema: {
                            candidates: ['name', 'position', 'email'],
                            employees: ['id', 'name', 'title'],
                            issues: ['type', 'severity', 'status'],
                            positions: ['title', 'manager', 'date'],
                          },
                          src: 'https://pendo-static-6591622502678528.storage.googleapis.com/aMWfxQOEkuJp4VuCXMEJQUBQIJ8/guide-media-cd1fdd27-4597-4af1-bb5b-e03bf2b75bc9',
                          baseUrl: '/hr/tableData/',
                        },
                        id: '',
                        classes: '',
                        type: 'QuickInfo',
                      },
                    },
                    id: 'quick-info',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                  sm: 7,
                  md: 8,
                  lg: 9,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 0.5,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'Updates',
                      },
                      content: {
                        styles: {},
                        opts: {
                          tabs: [
                            'New Note',
                            'Email',
                            'Call',
                            'Log Activity',
                            'Create Document',
                            'Schedule',
                          ],
                        },
                        id: '',
                        classes: '',
                        type: 'TabbedInput',
                      },
                    },
                    id: '',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
              {
                styles: {},
                opts: {
                  item: true,
                  xs: 12,
                },
                id: '',
                classes: '',
                componentName: 'Grid',
                contents: [
                  {
                    styles: {},
                    opts: {
                      height: 0.5,
                      header: {
                        styles: {},
                        opts: {},
                        id: '',
                        classes: '',
                        name: 'Timeline',
                      },
                      content: {
                        styles: {},
                        opts: {
                          entries: [
                            {
                              icon: 'NoteAlt',
                              text: 'Status unchanged, committed to next steps as sent previously.',
                            },
                            {
                              icon: 'Email',
                              text: 'Send update, including current status and next steps.',
                            },
                            {
                              icon: 'Phone',
                              text: 'Had a phone conversation discussing next steps.',
                            },
                            {
                              icon: 'Build',
                              text: 'Had a conversation concerning issue. Resolved.',
                            },
                            {
                              icon: 'CalendarToday',
                              text: 'Meeting Scheduled November 15, 2020.',
                            },
                            {
                              icon: 'CheckBox',
                              text: 'Confirmed receipt.',
                            },
                          ],
                        },
                        id: '',
                        classes: '',
                        type: 'Timeline',
                      },
                    },
                    id: '',
                    classes: '',
                    componentName: 'ContentTile',
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  footer: {
    styles: {},
    opts: {},
    id: 'footer',
    classes: '',
    typography: {
      styles: {},
      opts: {},
      id: '',
      classes: '',
      icon: {
        styles: {
          color: '#0d88e6',
        },
        opts: {},
        id: '',
        classes: 'footer-icon',
      },
    },
  },
};
